<template>
  <v-dialog
    v-model="dialogGasto"
    width="800px"
    @click:outside="$emit('update:dialogGasto', false)"
    @keydown.esc="$emit('update:dialogGasto', false)"
  >
    <v-card>
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        {{ $tc("global.gasto") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogGasto', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-row d-flex class="pb-2">
          <!-- Fornecedor -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="fatura_gasto.fornecedor_nome"
              :label="$tc('global.fornecedor')"
              outlined
              readonly
              hide-details
              dense
              required
            ></v-text-field>
          </v-col>
          <!-- RUC -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="fatura_gasto.fatura_ruc"
              label="RUC"
              outlined
              hide-details
              dense
              readonly
              required
            ></v-text-field>
          </v-col>

          <!-- MOEDA -->
          <v-col cols="12" md="4">
            <v-combobox
              v-model="selectedMoeda"
              :label="$tc('global.moeda', 2)"
              :items="moedas"
              dense
              readonly
              outlined
              required
              hide-details
              item-text="sigla"
              item-value="id"
            ></v-combobox>
          </v-col>
          <!-- Isento -->
          <v-col cols="12" md="4">
            <v-text-field
              ref="valorIsento"
              v-model="valorIsento"
              :label="$tc('global.isento')"
              dense
              :prefix="selectedMoeda ? fatura_gasto.moeda_sigla : ''"
              outlined
              hide-details
              readonly
              height="40px"
              v-currency="vCurrencyOptions"
            ></v-text-field>
          </v-col>
          <!-- IVA10 -->
          <v-col cols="12" md="4">
            <v-text-field
              ref="valorIva10"
              v-model="valorIva10"
              label="IVA 10%"
              dense
              hide-details
              :prefix="selectedMoeda ? fatura_gasto.moeda_sigla : ''"
              outlined
              readonly
              height="40px"
              style="display: flex"
              v-currency="vCurrencyOptions"
            >
              <template v-slot:append>
                <v-chip small v-if="fatura_gasto.moeda_sigla === 'G$'">
                  {{ fatura_gasto.moeda_sigla }}
                  {{ valorIva10Calculado | guarani }}
                </v-chip>
                <v-chip small v-else>
                  {{ fatura_gasto.moeda_sigla }}
                  {{ valorIva10Calculado | currency }}
                </v-chip>
              </template>
            </v-text-field>
          </v-col>
          <!-- IVA5 -->
          <v-col cols="12" md="4">
            <v-text-field
              ref="valorIva5"
              v-model="valorIva5"
              label="IVA 5%"
              dense
              readonly
              hide-details
              :prefix="selectedMoeda ? fatura_gasto.moeda_sigla : ''"
              outlined
              height="40px"
              style="display: flex"
              v-currency="vCurrencyOptions"
            >
              <template v-slot:append>
                <v-chip small v-if="fatura_gasto.moeda_sigla === 'G$'">
                  {{ fatura_gasto.moeda_sigla }}
                  {{ valorIva5Calculado | guarani }}
                </v-chip>
                <v-chip small v-else>
                  {{ fatura_gasto.moeda_sigla }}
                  {{ valorIva5Calculado | currency }}
                </v-chip>
              </template>
            </v-text-field>
          </v-col>
          <!-- total -->
          <v-col class="d-flex px-4 text-h6" cols="12">
            {{
              $tc("global.imposto") +
              `: ${selectedMoeda ?  item.moeda_sigla : ""}`
            }}
            <span v-if="selectedMoeda && item.moeda_sigla === 'G$'">{{
              valorTotalImposto | guarani
            }}</span>
            <span v-else>{{ valorTotalImposto | currency }}</span>
            <v-spacer></v-spacer>
            {{
              $tc("global.total") +
              `: ${selectedMoeda ? item.moeda_sigla : ""}`
            }}
            <span v-if="selectedMoeda && item.moeda_sigla === 'G$'">{{
              valorTotal | guarani
            }}</span>
            <span v-else>{{ valorTotal | currency }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DialogDetalhesGasto",

  components: {
    // DataField: () => import("@/components/fields/DataField.vue"),
  },

  props: {
    dialogGasto: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      fatura_gasto: {},
      valorIva10: 0,
      valorIva10Calculado: 0,
      valorIva5: 0,
      valorIva5Calculado: 0,
      valorTotal: 0,
      valorIsento: 0,
      selectedMoeda: null,
      valorTotalImposto: 0,
    };
  },

  computed: {
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
  },

  watch: {},

  methods: {},

  mounted() {
    this.fatura_gasto = this.item;
    this.selectedMoeda = this.moedas.find(
      (moeda) => moeda.id_moeda == this.item.moeda_id
    );
    this.$ci.setValue(this.$refs.valorIsento, this.fatura_gasto.isento);
    this.$ci.setValue(this.$refs.valorIva10, this.fatura_gasto.total_iva_10);
    this.$ci.setValue(this.$refs.valorIva5, this.fatura_gasto.total_iva_5);
    this.valorTotalImposto = this.fatura_gasto.total_iva;
    this.valorTotal = this.fatura_gasto.valor;
    this.valorIva5Calculado =
      this.fatura_gasto.total_iva_5 - this.fatura_gasto.iva_5;
    this.valorIva10Calculado =
      this.fatura_gasto.total_iva_10 - this.fatura_gasto.iva_10;
  },
};
</script>

<style>
</style>